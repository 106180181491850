// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-rules-de-js": () => import("./../../../src/pages/rules-de.js" /* webpackChunkName: "component---src-pages-rules-de-js" */),
  "component---src-pages-rules-en-js": () => import("./../../../src/pages/rules-en.js" /* webpackChunkName: "component---src-pages-rules-en-js" */),
  "component---src-pages-rules-fr-js": () => import("./../../../src/pages/rules-fr.js" /* webpackChunkName: "component---src-pages-rules-fr-js" */),
  "component---src-pages-solution-js": () => import("./../../../src/pages/solution.js" /* webpackChunkName: "component---src-pages-solution-js" */),
  "component---src-pages-tc-de-js": () => import("./../../../src/pages/tc-de.js" /* webpackChunkName: "component---src-pages-tc-de-js" */),
  "component---src-pages-tc-en-js": () => import("./../../../src/pages/tc-en.js" /* webpackChunkName: "component---src-pages-tc-en-js" */),
  "component---src-pages-tc-fr-js": () => import("./../../../src/pages/tc-fr.js" /* webpackChunkName: "component---src-pages-tc-fr-js" */)
}

